// @ts-check
/**
 * @fileoverview date range component
 *
 * usage:
 * <code>
 *     const {now, plusDT, minusDT} = useContext(DateContext);
 *     const {period, setPeriod} = useState({start: now(), end: plusDT(now(), "year", 1)});
 *     const handleChangePeriod = (date, name) => {
 *     setPeriod((prevState) => ({
 *             ...prevState,
 *             [name]: date
 *         }));
 *     };
 *
 *      return <DateRange
 *          title={t("TimeslotDialog.period")}
 *          min={minusDT(now(), "year", 2)}
 *          max={plusDT(now(), "year", 5)}
 *          styles={{inputDate: classes.inputDate}}
 *          values={period}
 *          onChange={handleChangePeriod}
 *       />
 * </code>
 */

import {FormControlLabel, Switch, TextField} from "@mui/material";
import {DatePicker as DateRangePicker} from "@mui/x-date-pickers/DatePicker";
import PropTypes from "prop-types";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import useStyles from "./date_range.styles";
/**
 * @typedef StylesDateRange
 * @type {Object}
 * @property {String} [marginBetween]
 * @property {String} [inputDate]
 * @property {String} [switchText]
 */
/**
 * DateRange component
 * @param {Object} props
 * @param {StylesDateRange} [props.styles]
 * @param {{start: Boolean, end: Boolean}} [props.disabled]
 * @param {({start: string, end: string}|String)} props.title
 * @param {({start: DateTimeType, end: DateTimeType}|DateTimeType)} [props.min] DateTime object
 * @param {({start: DateTimeType, end: DateTimeType}|DateTimeType)} [props.max] DateTime object
 * @param {{start: DateTimeType, end: DateTimeType}} props.values DateTime object
 * @param {Function} props.onChange
 * @param {Boolean} [props.allowNoEndDate]
 * @param {Boolean} [props.noEndDate]
 * @param {Function} [props.onToggleNoEndDate]
 * @param {Boolean} [props.switchDisabled]
 * @return {React.ReactElement}
 */
const DateRange = ({styles, disabled, title, min, max, values, onChange, allowNoEndDate, noEndDate, onToggleNoEndDate, switchDisabled}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const {now, plusDT, getLuxonToken, fromFormat} = useContext(DateContext);

    const labels =
        typeof title === "string"
            ? {
                  start: title,
                  end: ""
              }
            : title;
    const disables =
        typeof disabled === "boolean" || typeof disabled === "undefined"
            ? {
                  start: disabled,
                  end: disabled
              }
            : disabled;

    const minDate = (item) => {
        const minimumValue = min && min[item] ? min[item] : min;

        if (item === "end") {
            // end should not be before start
            return values.start > minimumValue ? values.start : minimumValue;
        }

        return minimumValue;
    };

    const maxDate = (item) => {
        if (!max) return plusDT(now(), "year", 10);
        const maximumValue = max && max[item] ? max[item] : max;

        if (item === "start") {
            // start should not be after end
            return values.end < maximumValue ? values.end : maximumValue;
        }

        return maximumValue;
    };

    const handleBlur = (item) => (e) => {
        onChange(fromFormat(e.target.value, DATE_FORMATS.DATE), item);
    };
    const handleChange = (date, item) => {
        if (date?.isValid) {
            onChange(date, item);
        }
    };
    const dateRangeElement = ["start", "end"].map((item) => (
        <div
            className={cx({
                [classes.marginBetween]: item === "end",
                [styles.marginBetween]: styles.marginBetween && item === "end"
            })}
            key={"dateRange-" + item}
        >
            {item === "end" && <div className={cx(classes.to, {[classes.disabled]: disables[item]})}>{t("App.to")}</div>}
            <DateRangePicker
                disabled={disables[item]}
                disableMaskedInput
                InputAdornmentProps={{position: "start"}}
                inputFormat={getLuxonToken(DATE_FORMATS.DATE)}
                label={labels[item]}
                maxDate={maxDate(item)}
                // These properties were used to show an error message under the input field
                // But it broke the UI and it was not optimal. Need to be discussed where and what kind of messages to be show
                // invalidDateMessage={invalidDateMessage || t("DateRange.invalidDateMessage")}
                // maxDateMessage={maxDateMessage || t("DateRange.maxDateMessage")}
                // minDateMessage={minDateMessage || t("DateRange.minDateMessage")}
                minDate={minDate(item)}
                renderInput={(textFieldProps) => (
                    // @ts-ignore
                    <TextField
                        variant="standard"
                        {...textFieldProps}
                        classes={{
                            root: cx(classes.inputDate, {
                                [styles.inputDate]: styles.inputDate,
                                [classes.mtEnd]: item === "end"
                            })
                        }}
                        inputProps={{...textFieldProps.inputProps, "data-testid": `dateRange-input-${item}`}}
                        onBlur={handleBlur(item)}
                    />
                )}
                value={values[item]}
                onChange={(date) => handleChange(date, item)}
            />
        </div>
    ));
    const noEndDateSwitch = allowNoEndDate && (
        <div className={classes.switch}>
            <FormControlLabel
                classes={{label: cx(classes.switchText, styles.switchText)}}
                control={<Switch checked={noEndDate} color="primary" size="small" value={noEndDate} onChange={() => onToggleNoEndDate()} />}
                disabled={switchDisabled}
                label={t("DateRange.noEndDate")}
                labelPlacement="start"
            />
        </div>
    );
    return (
        <div>
            <div style={{display: "flex", minWidth: "15rem"}}>{dateRangeElement}</div>
            {noEndDateSwitch}
        </div>
    );
};

DateRange.propTypes = {
    styles: PropTypes.shape({
        marginBetween: PropTypes.string,
        inputDate: PropTypes.string,
        switchText: PropTypes.string
    }),
    disabled: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            start: PropTypes.bool,
            end: PropTypes.bool
        })
    ]),
    values: PropTypes.shape({
        start: PropTypes.object, // DateTime object
        end: PropTypes.object // DateTime object
    }),
    onChange: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string
        })
    ]).isRequired,
    min: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.shape({
            start: PropTypes.object,
            end: PropTypes.object
        })
    ]),
    max: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.shape({
            start: PropTypes.object,
            end: PropTypes.object
        })
    ]),
    allowNoEndDate: PropTypes.bool,
    noEndDate: PropTypes.bool,
    onToggleNoEndDate: PropTypes.func,
    switchDisabled: PropTypes.bool,
    disableToolbar: PropTypes.bool
};

DateRange.defaultProps = {
    styles: {}
};

export default DateRange;
