/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {SvgIcon} from "@mui/material";

const ExpandIcon = ({size, className}) => {
    return (
        <SvgIcon style={{fontSize: size}} className={className} viewBox="0 0 24 24">
            <g clipPath="url(#clip0_3035_86718)">
                <path d="M4 20H20V22H4V20ZM4 2H20V4H4V2ZM13 9H16L12 5L8 9H11V15H8L12 19L16 15H13V9Z" fill="#00a099"/>
            </g>
            <defs>
                <clipPath id="clip0_3035_86718">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

ExpandIcon.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string
};

ExpandIcon.defaultProps = {
    size: "1rem"
};

export default ExpandIcon;
