// @ts-check
import {Close} from "@mui/icons-material";
import {Checkbox, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import _ from "lodash";
import {array, bool, func, object, string} from "prop-types";
import React, {useState} from "react";
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";

import useStyles from "../controlled_form_styles";

/** @typedef {import('react-hook-form').Control} Control */
/** @typedef {import('react-hook-form').UseFormGetValues<any>} UseFormGetValues */
/** @typedef {import('react-hook-form').FieldError} FieldError */
// /** @typedef {import('react-hook-form').FieldValues} FieldValues */

/**
 *
 * @param {Object} props
 * @param {Object} props.control
 * @param {FieldError|FieldError[]|any} [props.errors]
 * @param {Array<{label: string, value: string}>} props.items
 * @param {boolean} [props.disableReset=false]
 * @param {string} props.title
 * @param {boolean} [props.disabled]
 * @param {string} props.name
 * @param {object} props.classesStyle
 * @param {function} props.reset
 * @param {UseFormGetValues} props.getValues
 * @param {string} [props.placeholder]
 * @param {Function} [props.extraHandler] Handle cases whenever is needed to add an extra step to the selection
 * @param {string} [props.dataTestid] data-testid for testing
 * @param {boolean} [props.hasOwnErrorMessage] Whether the input show an error message below itself
 * @return {JSX.Element}
 */
export const ControlledSelectorMultiple = ({
    control,
    errors,
    items,
    disableReset = false,
    title,
    disabled = false,
    name,
    classesStyle,
    reset,
    getValues,
    placeholder,
    extraHandler,
    dataTestid = "controlled-selector-multiple",
    hasOwnErrorMessage = true
}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const {classes, cx} = useStyles();
    const hasError = !_.isEmpty(errors);

    /**
     * @param {React.MouseEvent<SVGSVGElement, MouseEvent>} e
     * @param {string} name
     */
    const handleResetClick = (e, name) => {
        e.stopPropagation();
        reset({...getValues(), [name]: []}, {keepDefaultValues: true});
    };

    const handleOnOpen = (e) => {
        // If reset icon is clicked, do not open menu.
        if (e.target.id !== "resetCommonSelector" && e.target.id !== "resetCommonSelectorSingle" && !e.target.closest("path")) {
            setOpen(true);
        }
    };
    /**
     * Render the selected value.
     * @param {Array<string>} value
     * @return {React.ReactNode}
     */
    const handleRenderValue = (value) => {
        const selected = items.find((item) => item.value === value[0]);
        if (!value || value.length === 0) return <div className={classes.placeholder}>{placeholder || t("App.pleaseSelect")}</div>;
        return (
            <div className={classes.flex}>
                <div className={classes.text}>
                    {selected?.label}
                    &nbsp;
                    {value.length > 1 && `(+${value.length - 1})`}
                </div>
                {!disableReset && (
                    <Close className={classes.closeIcon} id="resetCommonSelector" onClick={(e) => handleResetClick(e, name)} />
                )}
            </div>
        );
    };

    /**
     * Handle changes whenever an extraHandler function is passed as props
     * @param {Array<string>} value The list of selected values
     */
    const handleChange = (value) => {
        if (typeof extraHandler === "function") {
            extraHandler(value);
        }
    };

    return (
        <FormControl classes={{root: cx(classesStyle && classesStyle)}} data-testid={dataTestid} error={hasError} variant="standard">
            {title && <InputLabel shrink>{title}</InputLabel>}
            <Controller
                control={control}
                name={name}
                render={({field: {value, onChange}}) => (
                    <Select
                        disabled={disabled}
                        displayEmpty
                        MenuProps={{variant: "menu"}}
                        multiple
                        open={open}
                        renderValue={handleRenderValue}
                        value={typeof value === "object" ? value : []}
                        variant="standard"
                        onChange={(e) => {
                            /** @type {string[]} */
                            // @ts-ignore
                            const selectedValues = e.target.value;
                            onChange(e);
                            handleChange(selectedValues);
                        }}
                        // In order to avoid crash by providing wrong data type (string), check the value at first
                        onClose={() => setOpen(false)}
                        // This is a bug in Material UI, fixed in v5
                        onOpen={handleOnOpen}
                    >
                        {items.map((item) => {
                            if (item.value === "divider") return <Divider className={classes.thinDivider} key="divider" />;
                            return (
                                <MenuItem
                                    className={cx(classes.menu, classes.withCheckBox)}
                                    disabled={disabled}
                                    key={item.label}
                                    value={item.value}
                                >
                                    <Checkbox checked={value.includes(item.value)} className={classes.checkbox} size="small" />
                                    {item.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                )}
            />
            {hasError && hasOwnErrorMessage && <FormHelperText className={classes.error}>{t(errors?.message)}</FormHelperText>}
        </FormControl>
    );
};

ControlledSelectorMultiple.propTypes = {
    control: object.isRequired,
    errors: object,
    items: array.isRequired,
    reset: func.isRequired,
    getValues: func.isRequired,
    disableReset: bool,
    disabled: bool,
    title: string.isRequired,
    name: string.isRequired,
    classesStyle: string,
    placeholder: string,
    extraHandler: func,
    dataTestid: string,
    hasOwnErrorMessage: bool
};
