/**
 * @fileOverview page wrapper component for all pages
 */

import {Box} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import {useDispatch, useSelector} from "react-redux";

import mainMenuStructure from "../../../../config/main_menu_structure";
import userMenuStructure from "../../../../config/user_menu_structure";
import {toggleMainMenu} from "../../../redux/actions/index";
import {selectShowFullMainMenu} from "../../../redux/app_selectors";
import {selectShowTeaser} from "../../../redux/events/event_selectors";
import {selectShowVerticalLine, selectVerticalLineBackground} from "../../fe_settings/fe_settings_selectors";
import Header from "../../header/header";
import MainMenu from "../../main_menu/main_menu";
import {UserNotificationsSnackbarContainer} from "../../notifications";
import Head from "./head";
import useStyles from "./page.styles";

const Page = ({
    children,
    organizationId,
    hasNotifications,
    name,
    title,
    renderOptions,
    headerItems,
    minPadding,
    fullActionMenubar,
    openRightLayer,
    editedBy,
    fullCanvas,
    showFullscreen,
    hasNoPadding
}) => {
    const {classes, cx} = useStyles();
    const dispatch = useDispatch();

    // redux
    const showFullMainMenu = useSelector(selectShowFullMainMenu);
    const showTeaser = useSelector(selectShowTeaser);
    const showVerticalLine = useSelector(selectShowVerticalLine);
    const verticalLineBackground = useSelector(selectVerticalLineBackground);

    /**
     * toggle main menu width
     */
    const toggleMainMenuWidth = () => {
        dispatch(toggleMainMenu());
    };

    if (!organizationId) return null;
    return (
        <div className={classes.root} data-testid={name}>
            {showVerticalLine && <div className={classes.verticalLine} style={{background: verticalLineBackground}} />}
            <Head title={title} />
            <Header
                defaultRoute="/schedule/dayview"
                editedBy={editedBy}
                headerItems={headerItems}
                menuStructure={mainMenuStructure.concat(userMenuStructure)}
                organizationId={organizationId}
                renderOptions={renderOptions}
            />
            {!showFullscreen && (
                <MainMenu
                    defaultRoute="/schedule"
                    primaryMenu={mainMenuStructure}
                    showFullMainMenu={showFullMainMenu}
                    userMenu={userMenuStructure}
                    onToggleMainMenu={toggleMainMenuWidth}
                />
            )}
            <Box
                className={cx(classes.content, {
                    [classes.contentView]: !minPadding && !hasNoPadding,
                    [classes.contentViewHeightMinPadding]: minPadding,
                    [classes.contentNoPadding]: hasNoPadding,
                    [classes.fullActionMenubar]: fullActionMenubar && !openRightLayer,
                    [classes.fullActionMenubarWithRightLayer]: fullActionMenubar && openRightLayer,
                    [classes.openRightLayer]: !fullActionMenubar && openRightLayer,
                    [classes.fullCanvas]: fullCanvas,
                    [classes.contentMaxMainMenu]: showFullMainMenu,
                    [classes.contentFullscreen]: showFullscreen,
                    [classes.fullscreenWithRightLayer]: showFullscreen && openRightLayer,
                    [classes.showTeaser]: showTeaser
                })}
                height={1}
            >
                {children}
                {hasNotifications && <UserNotificationsSnackbarContainer />}
            </Box>
        </div>
    );
};

Page.propTypes = {
    children: PropTypes.node.isRequired,
    organizationId: PropTypes.string,
    hasNotifications: PropTypes.bool,
    name: PropTypes.string,
    title: PropTypes.string,
    headerItems: PropTypes.array,
    renderOptions: PropTypes.func,
    minPadding: PropTypes.bool,
    fullActionMenubar: PropTypes.bool,
    openRightLayer: PropTypes.bool,
    editedBy: PropTypes.string,
    fullCanvas: PropTypes.bool,
    showFullscreen: PropTypes.bool,
    hasNoPadding: PropTypes.bool
};

Page.defaultProps = {
    hasNotifications: true
};

export default Page;
