// @ts-check
import {CheckCircle, Close} from "@mui/icons-material";
import {Divider, FormControl, FormHelperText, InputLabel, ListSubheader, MenuItem, Select} from "@mui/material";
import _ from "lodash";
import {array, bool, func, object, string} from "prop-types";
import React, {useState} from "react";
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";

import useStyles from "../controlled_form_styles";

/** @typedef {import('react-hook-form').UseFormGetValues<any>} UseFormGetValues */
/** @typedef {import('react-hook-form').FieldError} FieldError */

/**
 * @typedef {Object} OptionItem
 * @property {string} label
 * @property {string|number} value
 */
/**
 * @param {Object} props
 * @param {Object} props.control
 * @param {FieldError|undefined|any} [props.errors]
 * @param {Array<OptionItem>} props.items
 * @param {string} props.title
 * @param {boolean} [props.required]
 * @param {boolean} [props.disabled]
 * @param {string} props.name
 * @param {object} props.classesStyle
 * @param {string} [props.placeholder]
 * @param {boolean} [props.hasOwnErrorMessage=true]
 * @param {boolean} [props.showCheckIcon = false]
 * @param {boolean} [props.showResetIcon = false] If true, setValue function needs to be set
 * @param {function} [props.setValue] It is used to clear the field (onChange from the controller doesn't work)
 * @return {JSX.Element}
 */
export const ControlledSelectorSingle = ({
    control,
    errors,
    items,
    title,
    required,
    disabled = false,
    name,
    classesStyle,
    placeholder,
    setValue,
    hasOwnErrorMessage = true,
    showCheckIcon = false,
    showResetIcon = false
}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const {classes, cx} = useStyles();
    const hasError = !_.isEmpty(errors);

    const handleReset = () => {
        if (typeof setValue === "function") {
            setValue(name, "");
        }
    };
    /**
     * Render the selected value.
     * @param {String} value
     * @return {React.ReactNode}
     */
    const handleRenderValue = (value) => {
        const selected = items.find((item) => item.value === value);

        if (!selected) return <div className={classes.placeholder}>{placeholder}</div>;

        return (
            <div className={classes.textWrapper}>
                <div className={cx(classes.flex, classes.width100)}>
                    <div className={classes.text}>{selected?.label}</div>
                    {showCheckIcon && (
                        <CheckCircle className={classes.checkIcon} data-testid="single-selector-checkIcon" fontSize="small" />
                    )}
                </div>
                {showResetIcon && (
                    <Close
                        className={classes.clearIconSingle}
                        data-testid={`clear-${name}`}
                        onClick={handleReset}
                        onMouseDown={(e) => {
                            // In order to avoid opening the select menu
                            e.stopPropagation();
                        }}
                    />
                )}
            </div>
        );
    };

    return (
        <FormControl
            classes={{root: cx(classesStyle && classesStyle)}}
            data-testid={`single-selector-${name}`}
            error={hasError}
            variant="standard"
        >
            {title && (
                <InputLabel data-testid={"single-selector-label-" + name} required={required} shrink>
                    {title}
                </InputLabel>
            )}
            <Controller
                control={control}
                name={name}
                render={({field: {value, onChange}}) => (
                    <Select
                        defaultValue=""
                        disabled={disabled}
                        displayEmpty
                        inputProps={{"data-testid": "single-selector-input-" + name}}
                        MenuProps={{classes: {paper: classes.menuWrapper}}}
                        open={open}
                        renderValue={handleRenderValue}
                        value={value}
                        variant="standard"
                        onChange={onChange}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                    >
                        {items.map((item, i) => {
                            if (item.value === "divider") return <Divider className={classes.thinDivider} key="divider" />;
                            if (item.value === "subheader")
                                return (
                                    <ListSubheader className={classes.menuHeader} key={item.label} value="">
                                        {item.label}
                                    </ListSubheader>
                                );
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <MenuItem className={classes.menu} disabled={disabled} key={`${item.label}${i}`} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                )}
            />
            {hasError && hasOwnErrorMessage && <FormHelperText className={classes.error}>{t(errors.message)}</FormHelperText>}
        </FormControl>
    );
};

ControlledSelectorSingle.propTypes = {
    control: object.isRequired,
    errors: object,
    items: array.isRequired,
    disabled: bool,
    title: string.isRequired,
    required: bool,
    name: string.isRequired,
    classesStyle: string,
    placeholder: string,
    hasOwnErrorMessage: bool,
    showCheckIcon: bool,
    showResetIcon: bool,
    setValue: func
};
