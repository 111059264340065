// @ts-check
import {SvgIcon} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

/**
 * render InfoIcon with square form
 * @param {Object} props
 * @param {String} props.size
 * @param {String} props.className
 * @return {React.ReactElement}
 */
const InfoIcon = ({size, className}) => {
    return (
        <SvgIcon className={className} style={{fontSize: size}} viewBox="0 0 24 24">
            <path
                clipRule="evenodd"
                d="M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                fill="#616161"
                fillRule="evenodd"
            />
        </SvgIcon>
    );
};

InfoIcon.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string
};

InfoIcon.defaultProps = {
    size: "1rem"
};

export default InfoIcon;
