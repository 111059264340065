// @ts-check
/**
 * @fileoverview date range component
 *
 * usage:
 * <code>
 *     const {now, plusDT, minusDT} = useContext(DateContext);
 *     const {period, setPeriod} = useState({start: now(), end: now()});
 *     const handleChangePeriod = (date, name) => {
 *     setPeriod((prevState) => ({
 *             ...prevState,
 *             [name]: date
 *         }));
 *     };
 *
 *      return <DateRange
 *          title={t("TimeslotDialog.period")}
 *          min={minusDT(now(), "year", 2)}
 *          max={plusDT(now(), "year", 5)}
 *          styles={{inputDate: classes.inputDate}
 *          values={period}
 *          onChange={handleChangePeriod}
 *       />
 * </code>
 */

import {bool, object, oneOfType, shape, string} from "prop-types";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {ControlledDatePicker, ControlledSwitch} from "../controlled_form/";
import useStyles from "../date_range/date_range.styles";

/**
 * @description
 * This component accepts only usual object, not custom object like luxon or moment
 * So the values need to be provided as JS Date object
 * @link https://react-hook-form.com/api/useform/
 */

/**
 * @description
 * the props `values` needs to be provided to determine the minimum and maximum values
 */
/**
 *
 * @typedef {("start"|"end")} StartOrEnd
 */
/**
 * @typedef Styles
 * @type {Object}
 * @property {String} [marginBetween]
 * @property {String} [inputDate]
 * @property {String} [switchText]
 * @property {String} [to]
 */
/**
 * DateRange component
 * @param {Object} props
 * @param {Styles} [props.styles]
 * @param {{start: Boolean, end: Boolean}} props.disabled
 * @param {{start: string, end: string}} props.title
 * @param {({start: DateTimeType, end: DateTimeType}|DateTimeType)} [props.min] DateTime object
 * @param {({start: DateTimeType, end: DateTimeType}|DateTimeType)} [props.max] DateTime object
 * @param {{start: Date, end: Date}} props.values JS Date object, not DateTime because RHF can't handle custom object like luxon
 * @param {Boolean} props.switchDisabled
 * @param {Object} props.control
 * @param {Object} [props.errors]
 * @param {Boolean} [props.hideSwitch]
 * @return {React.ReactElement}
 */
export const DateRangeNew = ({styles, disabled, title, min, max, values, switchDisabled, control, errors, hideSwitch}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const {now, plusDT, getLuxonToken} = useContext(DateContext);

    const labels =
        typeof title === "string"
            ? {
                  start: title,
                  end: ""
              }
            : title;
    const disables =
        typeof disabled === "boolean" || typeof disabled === "undefined"
            ? {
                  start: disabled,
                  end: disabled
              }
            : disabled;

    const minDate = (/** @type {StartOrEnd} */ startOrEnd) => {
        const minimumValue = min && min[startOrEnd] ? min[startOrEnd] : min;

        if (startOrEnd === "end") {
            // end should not be before start
            return values.start > minimumValue ? values.start : minimumValue;
        }

        return minimumValue;
    };

    const maxDate = (/** @type {StartOrEnd} */ startOrEnd) => {
        if (!max) return plusDT(now(), "year", 10);
        const maximumValue = max && max[startOrEnd] ? max[startOrEnd] : max;

        if (startOrEnd === "start") {
            // start should not be after end
            return values.end < maximumValue ? values.end : maximumValue;
        }

        return maximumValue;
    };

    const dateRangeElement = ["start", "end"].map((/** @type {StartOrEnd} */ startOrEnd) => (
        <div
            className={cx({
                [classes.marginBetween]: startOrEnd === "end",
                [styles.marginBetween]: styles.marginBetween && startOrEnd === "end"
            })}
            key={"dateRange-" + startOrEnd}
        >
            {startOrEnd === "end" && (
                <div
                    className={cx(classes.to, {
                        [classes.disabled]: disables[startOrEnd],
                        [styles.to]: styles.to
                    })}
                >
                    {t("App.to")}
                </div>
            )}
            <ControlledDatePicker
                control={control}
                disabled={disables[startOrEnd]}
                errors={errors[`dateRange_${startOrEnd}`]}
                hasOwnErrorMessage={false}
                inputFormat={getLuxonToken(DATE_FORMATS.DATE)}
                label={labels[startOrEnd]}
                maxDate={maxDate(startOrEnd)}
                minDate={minDate(startOrEnd)} // check if need it
                name={`dateRange_${startOrEnd}`}
                startOrEnd={startOrEnd}
                styles={styles}
            />
        </div>
    ));

    return (
        <div>
            <div style={{display: "flex", minWidth: "15rem"}}>{dateRangeElement}</div>
            {!hideSwitch && (
                <div className={classes.switch}>
                    <ControlledSwitch
                        classesStyle={classes.switchTextNew}
                        control={control}
                        cx={cx}
                        label={t("DateRange.noEndDate")}
                        labelPlacement="start"
                        name="hasNoEndDate"
                        switchDisabled={switchDisabled}
                    />
                </div>
            )}
        </div>
    );
};

DateRangeNew.propTypes = {
    styles: shape({
        marginBetween: string,
        inputDate: string,
        switchText: string,
        to: string
    }),
    disabled: oneOfType([
        bool,
        shape({
            start: bool,
            end: bool
        })
    ]),
    values: shape({
        start: object, // DateTime object
        end: object // DateTime object
    }).isRequired,
    // onChange: func.isRequired,
    title: oneOfType([
        string,
        shape({
            start: string,
            end: string
        })
    ]).isRequired,
    min: oneOfType([
        object,
        shape({
            start: object,
            end: object
        })
    ]),
    max: oneOfType([
        object,
        shape({
            start: object,
            end: object
        })
    ]),
    switchDisabled: bool,
    control: object.isRequired,
    errors: object,
    hideSwitch: bool
};

DateRangeNew.defaultProps = {
    styles: {}
};
