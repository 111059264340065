/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {SvgIcon} from "@mui/material";

const FoldIcon = ({size, className}) => {
    return (
        <SvgIcon style={{fontSize: size}} className={className} viewBox="0 0 24 24">
            <g clipPath="url(#clip0_3035_87962)">
                <path d="M8 19H11V22H13V19H16L12 15L8 19ZM16 4H13V1H11V4H8L12 8L16 4ZM4 9V11H20V9H4Z" fill="#00a099"/>
                <path d="M4 12H20V14H4V12Z" fill="#00a099"/>
            </g>
            <defs>
                <clipPath id="clip0_3035_87962">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>

        </SvgIcon>
    );
};

FoldIcon.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string
};

FoldIcon.defaultProps = {
    size: "1rem"
};

export default FoldIcon;
