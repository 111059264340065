import {Close} from "@mui/icons-material";
import {Checkbox, Divider, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import PropTypes from "prop-types";
import React, {Fragment, useState} from "react";

import useStyles from "./common_selector.styles";

const CommonSelector = ({
    title,
    items,
    value,
    required,
    onChange,
    styles,
    multiple,
    placeholder,
    disabled,
    error,
    onReset,
    disableReset,
    fullWidth
}) => {
    const {classes, cx} = useStyles();

    const [open, setOpen] = useState(false);
    const handleResetClick = (e) => {
        e.stopPropagation();
        onReset();
    };
    return (
        <FormControl
            classes={{
                root: cx({
                    [classes.fullWidth]: fullWidth
                })
            }}
            data-testid={`single-selector-${title}`}
            required={required}
            variant="standard"
        >
            {title && <InputLabel shrink>{title}</InputLabel>}
            <Select
                disabled={disabled}
                displayEmpty
                error={error}
                inputProps={{
                    "className": cx(classes.input, styles && styles.input),
                    "data-testid": `common-selector-${title}`
                }}
                MenuProps={{
                    classes: {
                        paper: cx(classes.menuWrapper, styles && styles.menuWrapper)
                    },
                    variant: "menu"
                }}
                multiple={multiple}
                open={open}
                renderValue={(value) => {
                    if (multiple) {
                        if (!value || !value.length) return <span className={classes.placeholder}>{placeholder}</span>;
                        const selected = items.find((item) => item.value === value[0]);
                        return value.length === 1 ? (
                            <Fragment>
                                <div className={classes.text}>{selected && selected.label}</div>
                                {!disableReset && (
                                    <Close
                                        className={cx(classes.closeIcon, {
                                            [styles.closeIcon]: styles && styles.closeIcon
                                        })}
                                        id="resetCommonSelector"
                                        onClick={handleResetClick}
                                    />
                                )}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className={classes.text}>
                                    {selected?.label}&nbsp;{`(+${value.length - 1})`}
                                </div>
                                <Close
                                    className={cx(classes.closeIcon, {
                                        [styles.closeIcon]: styles && styles.closeIcon
                                    })}
                                    id="resetCommonSelector"
                                    onClick={handleResetClick}
                                />
                            </Fragment>
                        );
                    }
                    const result = items.filter((item) => item.value === value);
                    return result && result.length ? (
                        <Fragment>
                            <div className={classes.text}>{result[0].label}</div>
                            {!disableReset && (
                                <Close
                                    className={cx(classes.closeIcon, {
                                        [styles.closeIcon]: styles && styles.closeIcon
                                    })}
                                    id="resetCommonSelectorSingle"
                                    onClick={handleResetClick}
                                />
                            )}
                        </Fragment>
                    ) : (
                        <span className={classes.placeholder}>{placeholder}</span>
                    );
                }}
                value={value || ""}
                variant="standard"
                onChange={onChange}
                onClose={() => {
                    setOpen(false);
                }}
                onOpen={(e) => {
                    // If reset icon is clicked, do not open menu.
                    if (e.target.id !== "resetCommonSelector" && e.target.id !== "resetCommonSelectorSingle" && !e.target.closest("path")) {
                        setOpen(true);
                    }
                }}
            >
                {items.map((item) => {
                    if (item.value === "divider") {
                        return <Divider key="divider" />;
                    }
                    return (
                        <MenuItem
                            className={cx(classes.menu, {
                                [classes.withCheckBox]: multiple
                            })}
                            disabled={disabled}
                            key={item.value}
                            value={item.value}
                        >
                            {multiple && <Checkbox checked={value.indexOf(item.value) > -1} className={classes.checkbox} size="small" />}
                            {item.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
CommonSelector.propTypes = {
    title: PropTypes.node,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        })
    ),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    styles: PropTypes.shape({
        input: PropTypes.string,
        menuWrapper: PropTypes.string,
        menu: PropTypes.string,
        closeIcon: PropTypes.string
    }),
    multiple: PropTypes.bool,
    placeholder: PropTypes.node,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    onReset: PropTypes.func,
    disableReset: PropTypes.bool,
    fullWidth: PropTypes.bool
};
CommonSelector.defaultProps = {
    styles: {}
};
export default CommonSelector;
