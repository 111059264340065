// @ts-check
import {FormControl, FormHelperText, InputLabel, TextField} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import _ from "lodash";
import {bool, object, string} from "prop-types";
import React, {useContext} from "react";
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {DateContext} from "../../../../contexts/dates";
import useStyles from "../controlled_form_styles";

/**
 *
 * @param {object} props
 * @param {object} props.control
 * @param {string} props.name
 * @param {DateTimeType} [props.minDate]
 * @param {DateTimeType} [props.maxDate]
 * @param {string} props.inputFormat
 * @param {string} props.label
 * @param {boolean} [props.required]
 * @param {boolean} [props.disabled]
 * @param {("end"|"start")} props.startOrEnd
 * @param {object} [props.styles]
 * @param {boolean} [props.disableOpenPicker]
 * @param {boolean} [props.hasOwnErrorMessage=false]
 * @param {boolean} [props.readOnly=false]
 * @param {object} [props.errors]
 * @return {React.ReactElement}
 */
export const ControlledDatePicker = ({
    control,
    name,
    minDate,
    maxDate,
    inputFormat,
    label,
    required,
    disabled,
    startOrEnd,
    styles,
    disableOpenPicker,
    hasOwnErrorMessage = true,
    readOnly = false,
    errors
}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const hasError = !_.isEmpty(errors);
    const {fromJSDate} = useContext(DateContext);

    const inputLabel = (
        <InputLabel id={`date-picker-label-${name}`} required={required} shrink>
            {label}
        </InputLabel>
    );
    return (
        <FormControl error={hasError} variant="standard">
            <Controller
                control={control}
                defaultValue={null}
                name={name}
                render={({field: {value, onChange}}) => {
                    return (
                        <DatePicker
                            disabled={disabled}
                            disableMaskedInput={true}
                            disableOpenPicker={disableOpenPicker}
                            InputAdornmentProps={{position: "start"}}
                            inputFormat={inputFormat}
                            label={inputLabel}
                            maxDate={maxDate}
                            minDate={minDate}
                            renderInput={(props) => (
                                // @ts-ignore
                                <TextField
                                    variant="standard"
                                    {...props}
                                    classes={{
                                        root: cx(classes.inputDate, {
                                            [styles.inputDate]: styles.inputDate,
                                            [classes.mtEnd]: startOrEnd === "end"
                                        })
                                    }}
                                    error={hasError}
                                    inputProps={{
                                        // eslint-disable-next-line react/prop-types
                                        ...props.inputProps,
                                        "placeholder": t("App.pleaseSelect"),
                                        "aria-labelledby": `date-picker-label-${name}`,
                                        readOnly
                                    }}
                                />
                            )}
                            value={fromJSDate(value)}
                            onChange={(date) => {
                                onChange((date && date.toJSDate()) || date);
                            }}
                        />
                    );
                }}
            />
            {hasError && hasOwnErrorMessage && <FormHelperText className={classes.error}>{t(errors.message)}</FormHelperText>}
        </FormControl>
    );
};
ControlledDatePicker.propTypes = {
    control: object.isRequired,
    name: string.isRequired,
    disabled: bool,
    label: string.isRequired,
    required: bool,
    minDate: object,
    maxDate: object,
    inputFormat: string.isRequired,
    startOrEnd: string.isRequired,
    styles: object,
    disableOpenPicker: bool,
    hasOwnErrorMessage: bool,
    readOnly: bool,
    errors: object
};
